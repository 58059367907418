module.exports = {
  //PARAMETERS
  developmentFeatures: true, //false or not assigned to disable development features
  captchaTokenFront: "6LcjXu0aAAAAABpuC2mj1E5H7dsJ5hQ5QThJkubd",
  open_DOORS: false,
  more_payment_methods: false,
  access_code_digits: 4,
  "color-primary": "#c11909",
  "color-map-nodisponible": "#cda077", // cda077 keep this color if the company doesnt ask to change it to their primary color
  useAnalytics: false,
  use_MERCHANT: false, //USE mercantilRegister param
  use_FAX: false,
  use_LOCATION_PREP: false,
  use_StorageGroups: false,
  use_TEST_DATA: false, //AUTO FILL FORMS WITH TEST DATA

  //FEATURES
  ALLOW_CHANGE_STORAGE: false, //ALLOW CHANGE STORAGE FEATURE
  
  //CHECKOUT PARAMETERS
  USE_RETENTION: false, //Allowed 19% retention
  use_PRORRATION: true, //SHOW PRORRATION AT CHECKOUT
  use_CONTRACTS: true, //SHOW SIGNABLE CONTRACTS AT CHECKOUT
  use_SIGNABLE: false, //SHOW SIGNABLE CONTRACTS AT CHECKOUT
  use_ASSURE: true, //SHOW ASSURES AT CHECKOUT
  use_ASSURE_PRORRATION: false, //APPLY PRORRATION TO ASSURES AT CHECKOUT
  use_ASSURE_EXTENSION: false, //SHOW ASSURE EXTENSION
  use_DEPOSIT: false, //SHOW DEPOSIT AT CHECKOUT
  use_LIQUIDATION: false,
  
  //ADD CUSTOMER CHECKOUT PARAMETERS
  allow_CREATE_CUSTOMER_PURCHASE: true, //ALLOWS TO CREATE A SINGLE CUSTOMER WITHOUT PURCHASE
  allow_NORMAL_PURCHASE: true, //ALLOWS NORMAL PURCHASE
  allow_LACK_PURCHASE: true, //ALLOWS LACK PURCHASE
  allow_BUY_PRODUCTS_PURCHASE: true, //ALLOWS BUY PRODUCTS PURCHASE
  allow_BOOKING_PURCHASE: false, //ALLOWS BOOKING PURCHASE

  //PAYMENT PARAMETERS
  use_CARD_PAYMENT_FOR_WORKERS: true, //ENABLE CARD PAYMENTS
  use_SEPA_DEBIT_PAYMENT_FOR_WORKERS: false, //ENABLE SEPA_DEBIT PAYMENTS
  use_TRANSFER_PAYMENT_FOR_WORKERS: false, //ENABLE TRANSFER PAYMENTS
  use_CARD_PAYMENT_FOR_CUSTOMERS: true, //ENABLE CARD PAYMENTS
  use_SEPA_DEBIT_PAYMENT_FOR_CUSTOMERS: false, //ENABLE SEPA_DEBIT PAYMENTS
  use_TRANSFER_PAYMENT_FOR_CUSTOMERS: false, //ENABLE TRANSFER PAYMENTS
  use_PAY_AT_SHOP: false, //ENABLE TRANSFER PAYMENTS

  //PARAM NEW COMPANY
  withLanding: false,
  withIndoorOpening: true, //tiene puerta principal interna
  withStorageOpening: true, //storages doors

  companyName: "Alicante Trasteros S.L.",
  phone: "627 443 000",
  city: "Alicante",
  province: "Alicante",
  email: "info@trasterosalicantecentro.com",
  address: "CALLE BAILEN, 3 - BJ",
  postal_code: "03001",
  storageNaming: "BOX",
  commercialName: "Alicante Trasteros",
  payments_companyName: "Alicante Trasteros S.L.",
  payments_NIF: "B05405303",
  mercantilRegister: "",
  accountant_email: "info@trasterosalicantecentro.com",
  contract_email: "info@trasterosalicantecentro.com",
  url: "alquiler.trasterosalicantecentro.es",
  branch: "ALICANTE",

  //INVOICE PARAMETERS
  firstSizeM3: false,
  use_IRPF: false,
  IVA: 21, //IN PERCENT 21%
  RETENTION: 19, //IN PERCENT 19%
  CENTERNAME:'ES',
  automaticPayment:false,

  //CUSTOMER JS
  showUnsubcribeProcess:false,
  //URL
  workersURL:"/workers/login",

  CUSTOM_IMAGE_URL: 'https://trasterosalicantecentro.com/wp-content/uploads/2023/03/trasteros-alicante-centro-h.png',
  perPageLimit:5,
  showUnsubcribeProcess:false
};
